.overlay {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(216, 216, 216, 0.7);
	opacity: 0;
	transition: opacity 0.15s ease-out;
	border-radius: 50%;
}

.container {
	width: 140px;
	height: 140px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
}

.container:hover .overlay {
	opacity: 1;
}

.profileInput {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

label {
	margin-top: 6px;
}
