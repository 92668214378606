.table-condensed td{
    padding:5px !important;
}

.badge-small{
    padding:3px 10px !important;
    font-size: 11px !important;
}

.no-padding{
    padding:0px !important;
}

.modal-content{
    padding:20px;
}

.recharts-tooltip-wrapper {
    z-index: 1000 !important;
}