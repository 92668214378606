.container {
	position: relative !important;
	margin: 2em auto !important;
}

.contentFullscreen {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(95vh - 66px) !important;
}

.actionsFullscreen {
	position: absolute;
	bottom: 0;
	width: 100%;
}
