.ribbon {
	display: inline-block;
	position: absolute;
	top: 10px;
	left: -1em;
	padding: 7px 28.4px 7px 14.4px;
	min-width: max-content;
	line-height: 1;
	vertical-align: baseline;
	padding: 0.5833em 0.833em;
	background: #11cf99;
	color: #fff;
	text-transform: none;
	font-weight: 700;
	font-size: 0.85714286rem;
	border: 0 solid transparent;
	border-radius: 0 0.28571429rem 0.28571429rem 0;
	transition: background 0.1s ease;
}

.ribbon:after {
	position: absolute;
	top: 100%;
	right: auto;
	left: 0;
	content: '';
	border-style: solid;
	border-width: 1em 0 0 1em;
	border-color: transparent;
	border-top-color: #502aa1;
	background-color: transparent !important;
	width: 0;
	height: 0;
}
