.statusRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 11px;
}

.statusColumnLeft {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 50%;
	align-items: center;
}

.statusColumnRight {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 50%;
	align-items: center;
}

.badge {
	display: flex;
	margin: 0 0 0 11px;
}
