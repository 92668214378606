.dropContainer {
	/* width: 80%; */
	width: 100%;
	padding: 50px;
	text-align: center;
	color: grey;
	border: 1px dashed #ddd;
	border-radius: 4px;
	cursor: pointer;
	outline: none;

	transition: height 0.2s ease;
}

.dragActive {
	border-color: #78e5d5;
}

.dragReject {
	border-color: #e57878;
}
