.header {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-end;
	margin: 0;
}

.title {
	width: 100%;
	margin-bottom: 8px;
}

.listItemContainer {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
}

.icon {
	margin-left: 8px;
}

.docPdf {
	width: 100%;
	height: 100%;
}

.docImage {
	/* object-fit: cover; */
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.listHeaderContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.listItemsContainer {
	display: flex;
	align-items: center;
	margin: 0 0 4px 0;
}

.badge {
	margin-right: 6px;
}
