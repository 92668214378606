.card-filters{
    padding:8px 15px;

    & .btn{
        margin-bottom:0;
    }
}

.card-balances{
    // background:none !important;

}

.balances{
    margin:0px;
    height: 100%;
    padding:0px;
}

.balances-value{
    font-weight: bold;
}
.current-balance{
    padding:10px;
    height: 100%;
    border-radius: 6px;
    color:#777;
    
    h3{
        color:$color-accent !important;
    }

    &.selected{
        color:#FFF;
        background-color: $color-accent;

        h3{
            color:#FFF !important;
        }
    }

    
}

.receive-balance{
    padding:10px;
    height: 100%;
    border-left:none;
    color:#777; 
    
    &::after{
        content: '';
        right:10px;
        background-color: $color-blue; 
    }

    h3{
        color:$color-blue !important;
    }

    &.selected{
        border-radius: 6px;
        color:#FFF;
        background-color: $color-blue;

        h3{
            color:#FFF !important;
        }
    }
}