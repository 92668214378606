.container {
	@media screen and (min-width: 768px) {
		width: 100%;
		max-width: 1630px;
	}
}

.container__wrap {
	padding-left: 0;
	padding-top: 90px;
	padding-right: 8px;
	min-height: 100vh;
	transition: padding-left 0.3s;

	@include themify($themes) {
		background: themed('colorBackgroundBody');
	}

	@media screen and (min-width: 576px) {
		padding-left: 250px;
	}
}

.map .container__wrap {
  padding-top: 60px !important;
  padding-left: 239px !important;
  padding-right: 0;
}

.layout {
	&.layout--collapse {
		& + .container__wrap {
			padding-left: 0;

			@media screen and (min-width: 576px) {
				padding-left: 60px;
			}
		}
	}

	&.layout--top-navigation {
		& + .container__wrap {
			@media screen and (min-width: 576px) {
				padding-left: 0;
			}
		}
	}
}
