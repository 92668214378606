.card {
	text-align: center;
	background-color: #29e7b0 !important;
	color: #fff;
	font-size: 20px;
}

.card p {
	color: #fff;
}

.maxHeight {
	max-height: 382px;
	overflow-y: auto;
}
