.filter {
	margin-bottom: 1.5rem;
}

.row {
	display: flex;
	flex-direction: row !important;
	justify-content: flex-start;
}

.dateColumn {
	display: flex;
	flex-direction: column;
	width: 35%;
}

.containerDate {
	display: flex;
	flex-direction: row;
	width: 96%;
	height: 38px;
	border: 1px solid rgba(34, 36, 38, 0.15);
	border-radius: 0.28571429rem;
	align-items: center;
	align-self: flex-end;
	margin: 0 15px;
}

.dateDivider {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 8px;
	color: #aaa;
}

.dateInput {
	width: 50%;
	outline: none;
}

.dateInput div {
	border: none;
}

.dateInput button {
	display: none;
}

.searchbox {
	height: 38px;
	/* align-self: flex-end; */
	margin-bottom: 0;
	position: absolute !important;
	bottom: 0 !important;
}

.button {
	height: 38px;
	align-self: flex-end;
	margin-bottom: 0;
}

.searchboxWithLabel {
	display: block !important;
}

.center {
	display: flex;
	justify-content: flex-end !important;
}
