.container {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	/* flex-flow: wrap; */
	list-style-type: none;
	width: 100%;
	/* margin: 0.8rem 0; */
	/* display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(2rem, max-content)); */
}

.radio_button {
	/* justify-self: stretch; */
	white-space: nowrap;
	margin: 0.8rem 0 0;
}

.radio_button:not(:last-child) {
	margin-right: 0.4rem;
}

input[type='checkbox'] {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	background: lightgray;
	min-height: 20px;
	min-width: 20px;
	border: 1px solid white;
	border-radius: 4px;
	margin-right: 11px;
}

input[type='checkbox']:checked {
	background: #2185d0;
}

input[type='checkbox']:hover {
	filter: brightness(90%);
}

input[type='checkbox']:disabled {
	background: #e6e6e6;
	opacity: 0.6;
	pointer-events: none;
}

input[type='checkbox']:after {
	content: '';
	position: relative;
	left: 43%;
	width: 6px;
	height: 12px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg) translateY(2px);
	display: none;
}

input[type='checkbox']:checked:after {
	display: block;
}

input[type='checkbox']:disabled:after {
	border-color: #7b7b7b;
	border-radius: 4px;
}

.radio_button label {
	/* display: block; */
	display: flex;
	/* justify-content: flex-start; */
	align-items: center;
	cursor: pointer;
	text-align: left;
	/* line-height: 35px; */
	/* border-radius: 25px; */
	min-width: 36px;
	font-size: 13px;
	font-weight: 500;
	border-style: none;
	border-width: 2px;
	/* padding: 0 16px; */
	color: #616161;
	/* background-color: #eeeeee;
	border-color: #f5f5f5; */
	white-space: normal;
	/* word-wrap: break-word; */
	transition: all 0.2s ease;
}

/* .radio_button label.checked {
	--color: #000;
	color: #fff;
	background-color: var(--color);
	border-color: #e0e0e0;
} */
