.icon {
	margin: 0 2px 0 0;
	width: 15px !important;
	height: 15px !important;
}

.badge {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 14px !important;
	padding: 4px 0px !important;
}
