b {
  font-weight: 400 !important; }

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;

    & > * {
      margin: 4px; } } }

.company-details {
  position: fixed;
  right: 0;
  top: 60px;
  width: 600px;
  height: calc(100vh - 60px);
  z-index: 11;
  padding: 8px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 4px -1px;
  transform: translateX(100%);
  transition: transform .4s ease-in-out;

  &.active {
    transform: translateX(0); }

  & .close-btn {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 1.2rem;
    color: #666666; } }

.company-details__general-info,
.company-details__admins {
  padding: 24px 12px 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 100%;

  & span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: space-between; } }

.company-details__admins {
  align-items: stretch;
  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    background-color: #1e2328;
    border-radius: 5px;
    font-size: .8rem; } }

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

  & .card {
    padding: 12px 8px;
    box-shadow: rgba(0, 0, 0, 0.4) -1px 1px 4px -1px; } }

.title {
  font-weight: bold;
  text-align: left;
  padding: 4px 0 8px;
  margin-bottom: 8px;
  border-bottom: 2px solid rgba(66, 66, 66, 0.4); }

.title--not-bordered {
    border-bottom: none;
    margin-bottom: 0; }

.text {
  &-big {
    font-size: 1.7rem; }
  &-small {
    padding: 2px 0;
    font-size: 1.1rem;
    font-family: Helvetica; } }

.badges {
  display: flex;
  font-size: 1.1rem;

  & > *:not(:last-child) {
    margin: 0 4px; } }

.company-management {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(43%, 1fr));
  grid-gap: 4px;
  margin-top: 8px;

  & div {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 1.1rem;
    padding: 4px;
    transition: border .2s ease-in-out, background-color .4s ease;

    background-color: #1e2328;
    border-bottom: 3px solid darken(#1e2328, 10%);

    &:hover {
      background-color: darken(#1e2328, 10%); }

    &:active {
      border-bottom: 0; }

    &:nth-child(1) {
      background-color: #4BB543;
      border-bottom: 3px solid darken(#4BB543, 10%);
      &:hover {
        background-color: darken(#4BB543, 10%); } }

    &:nth-child(2) {
      background-color: #F00;
      border-bottom: 3px solid darken(#F00, 10%);
      &:hover {
        background-color: darken(#F00, 10%); } }
    &:nth-child(3) {
      background-color: #3263C6;
      border-bottom: 3px solid darken(#3263C6, 10%);
      &:hover {
        background-color: darken(#3263C6, 10%); } } } }

.edit-policy {
  position: absolute;
  left: 100%;
  top: 16px;
  width: 350px;
  height: 600px;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 4px -1px;
  border-radius: 12px;
  transition: left .4s ease-in-out, opacity .35s ease;
  opacity: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & button {
    appearance: none;
    position: absolute;
    bottom: 20px;
    width: 150px;
    left: calc(50% - 75px);
    margin: 0 auto;
    padding: 12px;
    border-radius: 15px; }

  &.active {
    left: -358px;
    opacity: 1; } }

.options {
  width: 100%;
  font-size: 1.2rem; }

.options div.option {
  width: 100%;
  margin: 12px 0;
  max-height: 40px;
  position: relative;

  & label {
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid rgba(#666666, 0.1);
    border-radius: 3px;
    transition: border .2s ease;
    &:hover, &:focus {
      border: 1px solid rgba(#666666, 0.5); } }

  & input[type="radio"] {
    opacity: 0;
    width: 0;
    background-color: #000; }

  & input[type="radio"]:checked ~ label {
    border: 1px solid rgba(#666666, 1); }

  & input[type="radio"]:not(:checked) ~ .hint {
    display: none; }

  & input[type="radio"]:checked ~ .hint {
    box-sizing: border-box;
    padding: 24px;
    position: absolute;
    right: calc(100% + 50px);
    top: 0;
    min-width: 300px;
    color: #FFFFFF;
    background-color: #3263C6;
    border-radius: 15px;
    //box-shadow: rgba(0, 0, 0, 0.4) 0 4px 4px -1px
    border-right: 2px solid darken(#3263C6, 20%);
    border-bottom: 2px solid darken(#3263C6, 20%); } }

.input-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 190px;
  margin-top: 10px;
  background-color: rgba(66, 66, 66, 0.1);

  & .input {
    display: flex;
    max-width: 100%;
    padding: 12px; }

  & .input input {
    appearance: none;
    display: block;
    margin: 0 auto;
    padding: 8px;
    padding-left: 12px;
    max-width: 50%;
    border: none;
    font-size: 1rem;
    outline: 0;
    border: 1px solid rgba(66, 66, 66, 0.1);

    &:first-child {
      border-radius: 15px 0 0 15px; }
    &:last-child {
      border-radius: 0 15px 15px 0; } } }

.single-input-container {
  margin: 10px auto;
  padding: 12px 12px;
  background-color: rgba(66, 66, 66, 0.1);

  & input {
    appearance: none;
    display: block;
    border: 1px solid rgba(66, 66, 66, 0.1);
    border-radius: 15px;
    padding: 8px;
    font-size: 1rem;
    font-family: Helvetica;
    width: 90%; } }

.ranges-control {
  display: block;
  text-align: right;

  & i {
    cursor: pointer;
    color: #666;
    padding: 8px 4px 0 12px; } }

.table-filter {
  display: flex;
  justify-content: flex-end;

  input {
    appearance: none;
    padding: 8px 12px;
    color: #666666;
    border: 1px solid rgba(66, 66, 66, 0.2);
    border-radius: 25px;
    font-size: 1rem; } }
